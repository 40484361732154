import React, { useEffect, useState } from 'react';
// import jobListings from "../../data/jobs.json";

const JobsWebPage = () => {
  const title = "My Jobs";

  return (
    <div>
      <MainContent title={title} />
      <Footer />
    </div>
  );
};

const JobsTable2 = () => {
  const [responseData, setResponseData] = useState(null);

  useEffect(() => {
    let isMounted = true; // Variable to track if the component is mounted

    const fetchJobs = async () => {
      try {
        const response = await fetch('http://127.0.0.1:5000/recommendation/jobs', { timeout: 10000 });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.text(); // Get the response as text
        if (isMounted) {
          setResponseData(data); // Set the response data directly as string
        }
      } catch (error) {
        console.error("Fetching jobs failed:", error);
        if (error.name === 'AbortError') {
          alert("Request timed out. Please try again later.");
        } else {
          alert("Error loading job listings: " + error.toString());
        }
      }
    };

    fetchJobs();

    // Clean-up function to set isMounted to false when component unmounts
    return () => {
      isMounted = false;
    };
  }, []); // Empty dependency array ensures that this effect runs only once when the component mounts

  return (
    <div>
      <pre>{responseData}</pre>
    </div>
  );
};



const JobsTable = () => {
  const [jobListings, setJobListings] = useState([]);
  const [tableHeadings, setTableHeadings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('http://127.0.0.1:5000/recommendation/jobs')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setJobListings(data);
        // Dynamically set table headings based on the keys of the first object in the response
        if (data.length > 0) {
          setTableHeadings(Object.keys(data[0]));
        }
        setIsLoading(false);
      })
      .catch(error => {
        console.error("Fetching jobs failed:", error);
        setError(error.toString());
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading job listings: {error}</div>;

  return (
    <div className="h-full w-full overflow-x-auto">
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {tableHeadings.map((heading) => (
              <th key={heading} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                <span className="font-bold leading-none text-black">
                  {heading.replace(/_/g, ' ').replace(/\b\w/g, letter => letter.toUpperCase())}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {jobListings.map((job, index) => (
            <tr key={index}>
              {tableHeadings.map((heading) => (
                <td key={`${index}-${heading}`} className="p-4 border-b border-blue-gray-50">
                  {heading === 'job_description'
                    ? `${job[heading].substring(0, 100)}...` // Show only first 100 characters
                    : job[heading]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const MainContent = ({ title }) => {
  return (
    <main className="container mx-auto p-4">
      <div className="grid grid-cols-1 gap-4">
        <section>
          <hgroup>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">{title}</h2>
            <h3 className="text-md mb-4 text-gray-600">Manage all your job postings here</h3>
          </hgroup>
          <JobsTable />
        </section>
      </div>
    </main>
  );
};

const JobRow = ({ job }) => {
  return (
    <tr>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        {job.title}
      </td>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        {job.description}
      </td>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        {job.company}
      </td>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        {job.open === "yes" ? "Open" : "Closed"}
      </td>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        <button className="text-blue-500 hover:text-blue-800">View Details</button>
      </td>
    </tr>
  );
};

const Footer = () => {
  return (
    <footer className="bg-gray-100 p-4 text-center">
      <div className="my-2">
        <button className="text-indigo-600 hover:text-indigo-800 px-4 py-2">Privacy Policy</button> •
        <button className="text-indigo-600 hover:text-indigo-800 px-4 py-2">Terms of Service</button> •
        <button className="text-indigo-600 hover:text-indigo-800 px-4 py-2">Help Center</button>
      </div>
      <div className="my-2">
        <a href="https://www.linkedin.com/company/your-company-name" className="text-indigo-600 hover:text-indigo-800 px-4 py-2">LinkedIn</a> •
        <a href="https://www.twitter.com/your-twitter-handle" className="text-indigo-600 hover:text-indigo-800 px-4 py-2">Twitter</a> •
        <a href="https://www.facebook.com/your-facebook-page" className="text-indigo-600 hover:text-indigo-800 px-4 py-2">Facebook</a>
      </div>
      <small className="block my-2">&copy; 2024 Recruiter Dashboard</small>
    </footer>
  );
};

export default JobsWebPage;
