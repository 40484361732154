import React from 'react';
import { BrowserRouter as Router, Routes, Route,  useLocation, Navigate } from 'react-router-dom';
import JobsWebPage from './components/JobsWebPageFull/JobsWebPage'; // Make sure the path is correct
import CandidateMatching from './components/CandidateMatching'; // Make sure the path is correct
import CandidateAnalysis from './components/CandidateAnalysis'; // Adjust the import path
import Navbar from './components/Navbar';
import RecruiterHome from './components/RecruiterHome';
import LiveInterview from './components/LiveInterview';
import CompanyDashboard from './components/CompanyDashboard/CompanyDashboard';
import AcumentRecruitmentSolutions from './components/AcumentLanding';


const RedirectToAcument = () => {
  return <Navigate to="/acument" replace />;
};

const App = () => {
  const location = useLocation();
  
  return (
    <div className="App">
      {/* {location.pathname !== '/' && <Navbar />} */}
      <Routes>
          <Route path="/" element={<RedirectToAcument />} />
          <Route path="/acument" element={<AcumentRecruitmentSolutions />} />
        {/* <Route exact path="/RecruiterHome" element={<RecruiterHome />}  />
        <Route path="/jobs" element={<JobsWebPage title="My Jobs" />}  />
        <Route path="/matchingtool" element={<CandidateMatching />} />
        <Route path="/liveinterview" element={<LiveInterview />} />
        <Route path="/companydashboard" element={<CompanyDashboard />} />
        <Route path="/candidateanalysis" element={<CandidateAnalysis />} /> */}
      </Routes>
    </div>
  );
};

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;
