// StatsCard.js
import React from 'react';
import {
    Card,
    CardBody
  } from "@material-tailwind/react";

const StatsCard = ({ title, value }) => {
  return (
    <Card>
      <CardBody className="flex justify-between">
        <span>{title}</span>
        <span>{value}</span>
      </CardBody>
    </Card>
  );
};

export default StatsCard;
