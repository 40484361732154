import React, {useEffect, useRef } from 'react';
import Logo from '../../Images/ACUMENT_BRAND_LOGO.png';
import HeroImg from '../../Images/Hero.gif';
import AImg from '../../Images/AI_Interview.png';
import CommunicationImg from '../../Images/Communication.png';
import DashboardImg from '../../Images/Dashboard.png'
import DEIIMG from '../../Images/DEI.png'
import Fairness from '../../Images/Fairness.png'
import DemoVid from '../../Images/AcumentSlideShow.mp4'
import Umar from '../../Images/Umar.jpeg'
import Anindya from '../../Images/Anindya.jpeg'
import { useNavigate } from 'react-router-dom';



const AcumentRecruitmentSolutions = () => {
    return (
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow">
          <HeroSection />
          <ChallengesSection />
          <SolutionsSection />
          <Founders />
          <DemoSection />
          <EthicalRecruitmentSection />
        </main>
      </div>
    );
  };
  
  const Header = () => (
    <header className="flex justify-between items-center p-4 bg-white shadow-md">
      {/* Assuming you have a logo.svg in your public folder */}
      <img src={Logo} alt="Acument Solutions Logo" className="h-8" />
    </header>
  );
  
  const HeroSection = () => (
    <section className="relative text-center bg-gray-100 overflow-hidden">
      <img src={HeroImg} alt="Revolutionizing Recruitment" className="w-full" />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full md:w-3/4 lg:w-2/5 bg-black bg-opacity-50 p-4 rounded-lg">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-white mb-4">Acument</h2>
        <p className="text-white mb-4 md:mb-6">An AI-driven platform that seamlessly integrates with existing ATS systems, offering a scientifically-backed, bias-mitigated recommendation engine, 
        and language inclusive, speech-enabled pre-screening. Reduce recruiter workload and elevate hiring with authenticity, diversity, and efficiency. </p>
      </div>
    </section>
  );

  const ChallengesSection = () => (
    <section className="bg-gray-900 p-10">
      <div className="container mx-auto">
      <h3 className="text-2xl font-semibold mb-8 text-center text-white">Challenges in Recruitment</h3>
        <p className="mb-6 text-gray-200 text-center">
          The recruitment industry faces several challenges, including manual processes, lack of DEI analytics, high contingent fees, and lack of communication.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 justify-center mx-auto">
          <ChallengeCard
            title="Manual and Time-Consuming Processes"
            details={[
              "Difficulty in matching candidates with job requirements.",
              "Tedious and labor-intensive assessment of candidate profiles.",
            ]}
          />
          <ChallengeCard
            title="Lack of DEI Analytics"
            details={[
              "Inability to assess diversity metrics such as gender, ethnicity, and neurodiversity.",
              "Risk of biased hiring practices due to lack of data-driven insights.",
            ]}
          />
          <ChallengeCard
            title="Inefficient Communication"
            details={[
              "Limited communication channels between recruiters, hiring managers, and candidates.",
              "Delayed responses and coordination issues during the recruitment process.",
            ]}
          />
          <ChallengeCard
            title="Employee Engagement"
            details={[
              "Lack of employee involvement and engagement in the recruitment process.",
              "Missed opportunities for employee referrals and contributions to talent acquisition.",
            ]}
          />
          <ChallengeCard
            title="High Contingency Fees"
            details={[
              "Businesses facing exorbitant contingency fees for recruitment services.",
              "Limited options for cost-effective recruitment solutions.",
            ]}
          />

        </div>
      </div>
    </section>
  );
  
  const ChallengeCard = ({ title, details }) => (
    <div className="max-w-sm shadow-lg rounded-lg overflow-hidden bg-white transform transition duration-500 hover:scale-105">
      <div className="p-4">
        <h4 className="text-black font-medium text-center mb-4">{title}</h4>
        <ul className="text-sm text-gray-500 list-disc pl-5 pr-5">
          {details.map((detail, index) => (
            <li key={index} className="mb-2 text-gray-500">{detail}</li>
          ))}
        </ul>
      </div>
    </div>
  );
  
  const SolutionsSection = () => (
    <section className="py-10 bg-gray-400" >
      <div className="container mx-auto px-4">
        <h3 className="text-2xl font-semibold mb-8 text-center">Our Solutions</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          <SolutionCard
            title="Candidate Matching and Assessment"
            img={DEIIMG}
            details={[
              "Utilizes AI-driven algorithms to match candidates with job requirements.",
              "Conducts detailed assessments of candidates' skills, experience, and cultural fit.",
              "Utilize cognitive psychology assessments to highlight soft and hard skills of candidates.",
            ]}
          />
          <SolutionCard
            title="Diversity and Inclusion"
            img={Fairness}
            details={[
              "Evaluates diversity metrics such as gender, ethnicity, and neurodiversity.",
              "Unbiased Algorithm",
              "Consider Accessibility Issues in the Recruitment Process",
            ]}
          />
          <SolutionCard
            title="Automated Communication Tools"
            img={CommunicationImg}
            details={[
              "Facilitates seamless interaction between recruiters, hiring managers, and candidates.",
              "Reduces manual work and streamlines the hiring process.",
            ]}
          />
          <SolutionCard
            title="AI Speech Based Interview Agent"
            img={AImg}
            details={[
              "Conducts detailed candidate interviews and provides data-driven recommendations.",
              "Acts as an advisor to potential employees.",
              "Reduces contingency fees for recruitment services.",
              "Allow selection of recruiter personality, and objectives",
            ]}
          />
          <SolutionCard
            title="Customizable Dashboard"
            img={DashboardImg}
            details={[
              "Provides clients with a customizable dashboard for tracking recruitment progress and accessing analytics reports.",
            ]}
          />
        <SolutionCard
            title="Advisory and referall"
            img={DashboardImg}
            details={[
              "Advisor enrollment",
              "Candidate review, interviews, and culture fit assessments",
              "Referral bonus incentives for successful hires",
              "Feedback mechanism for continuous improvement"
            ]}
          />
        </div>
      </div>
    </section>
  );
  
  const SolutionCard = ({ title, img, details, optional = false }) => (
    <div className={`max-w-sm ${optional ? 'opacity-75' : 'opacity-100'} shadow-lg rounded-lg overflow-hidden bg-white transform transition duration-500 hover:scale-105`}>
      <img src={img} alt={title} className="w-full h-48 object-cover object-center" />
      <div className="p-4">
        <h4 className="text-black font-medium text-center mb-4">{title}</h4>
        <ul className="text-sm text-gray-500 list-disc pl-5 pr-5">
          {details.map((detail, index) => (
             <li key={index} className="mb-2 text-left">{detail}</li>
          ))}
        </ul>
      </div>
    </div>
  );
  
  
  const EthicalRecruitmentSection = () => {
    const navigate = useNavigate();
  
    return (
      <section className="text-center p-10 bg-white">
        <div className="container mx-auto">
          <h3 className="text-2xl font-semibold mb-4">Join the Ethical Recruitment Movement</h3>
          <p className="mb-6 text-gray-600">
            Become a part of our mission to empower diversity and startups.
          </p>
          {/* <button
            onClick={() => navigate('/RecruiterHome')}
            className="text-white bg-blue-500 hover:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none"
            type="button"
          >
            Get Started
          </button> */}

          <button> <a href="https://docs.google.com/forms/d/e/1FAIpQLScQCFIRuCPSwV28uDOC_UHCsf8qaXi2GNpsto-i_EOMg7a4yw/viewform?usp=sf_link" rel="noreferrer">
            Waitlist and Inquiries </a>  </button>
        </div>
      </section>
    );
  };

  const DemoSection = () => {
    const videoRef = useRef(null);
  
    useEffect(() => {
      if (videoRef.current) {
        videoRef.current.playbackRate = 1.25; // Sets the playback speed to 2x
        videoRef.current.play().catch(error => console.log("Auto-play was prevented: ", error)); // Autoplay the video, catch any errors for autoplay restrictions
      }
    }, []);
  
    return (
      <section className="bg-gray-900 p-10">
        <div className="container mx-auto text-center">
          <h3 className="text-2xl font-semibold mb-4 text-gray-100">Watch Our Demo</h3>
          <p className="mb-6 text-gray-300">
            See how our platform can revolutionize your recruitment process.
          </p>
          <video
            ref={videoRef}
            className="w-full h-64 md:h-96"
            src={DemoVid}
            title="Acument Recruitment Solutions Demo"
            controls
            muted // To autoplay in most browsers, the video needs to be muted
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </section>
    );
  };



  const Founders = () => {
    return (
      <section className="bg-white p-10">
        <div className="container mx-auto text-center">
          <h3 className="text-2xl font-semibold mb-8">Meet Our Founders</h3>
          <div className="flex flex-wrap justify-center gap-10">
            {/* Founder 1: Amar */}
            <div className="max-w-sm rounded overflow-hidden shadow-lg bg-gray-100 transform transition duration-500 hover:scale-105">
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">Umar</div>
                <img src={Umar} alt="Umar" className="w-24 h-24 mb-4 mx-auto rounded-full" />
                <p className="text-gray-700 text-base">
                  Umar, a Computer Engineering graduate from the University of Waterloo, brings an expertise
                  in Software Engineering, ML, and Leadership.
                </p>
              </div>
              <div className="px-6 pt-4 pb-2">
                <a href="https://www.linkedin.com/in/umarfhu" className="inline-block bg-blue-500 rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">LinkedIn</a>
                <a href="mailto:umarfhu@gmail.com" className="inline-block bg-gray-500 rounded-full px-3 py-1 text-sm font-semibold text-white mb-2">Email</a>
              </div>
            </div>
            
            {/* Founder 2: Anindya */}
            <div className="max-w-sm rounded overflow-hidden shadow-lg bg-gray-100 transform transition duration-500 hover:scale-105">
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">Anindya</div>
                <img src={Anindya} alt="Anindya" className="w-24 h-24 mb-4 mx-auto rounded-full" />
                <p className="text-gray-700 text-base">
                  Anindya's expertise lies in the intersection of Cognitive Science, AI and Product Management.
                  His goal is to use his knowledge of the human mind and incorporate it into building socially
                  conscious products.
                </p>
              </div>
              <div className="px-6 pt-4 pb-2">
                <a href="https://www.linkedin.com/in/anindya-auveek-7103b665" className="inline-block bg-blue-500 rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">LinkedIn</a>
                <a href="mailto:anindya.auveeka@gmail.com" className="inline-block bg-gray-500 rounded-full px-3 py-1 text-sm font-semibold text-white mb-2">Email</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };


export default AcumentRecruitmentSolutions;
